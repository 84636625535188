<template>
  <div class="with-nav-bar">
    <van-nav-bar
      title="更新资料"
      left-text="返回"
      :left-arrow="true"
      :fixed="true"
      @click-left="$router.back()"
    />
    <van-field v-model="state.agent_name" label="代理商名称" disabled />
    <van-field v-model="state.agent_level" label="代理商等级" disabled />
    <van-field v-model="state.contact_name" label="联系人名称" />
    <van-field v-model="state.contact_phone" label="联系人手机" />
    <div class="py-4 action-button-area text-center">
      <van-button v-if="!canSubmit" type="primary" disabled
        >确定更新</van-button
      >
      <van-button v-else type="primary" @click="submitForm"
        >确定更新</van-button
      >
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { computed, onMounted, reactive } from "vue";
import { Field, Picker, Toast } from "vant";
import { updateAgentInfo } from "@/api/account.service";
export default {
  name: "StationDetail",
  components: {
    [Field.name]: Field,
    [Picker.name]: Picker,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const state = reactive({
      agent_name: "",
      agent_level: "",
      contact_name: "",
      contact_phone: "",
    });

    const canSubmit = computed(() => {
      if (!state.contact_name || !state.contact_phone) {
        return false;
      }
      return true;
    });

    const submitForm = async () => {
      try {
        await updateAgentInfo({
          contact_name: state.contact_name,
          contact_phone: state.contact_phone,
        });
        Toast.success('更新成功');
        router.back();
      } catch (error) {
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        Toast({ message: msg ? msg : "前端脚本异常", position: "bottom" });
        if (!msg) console.log(error);
      }
    };

    const loadAgentInfo = () => {
      const agent = store.getters.agentInfo;
      state.agent_name = agent.agent_name;
      state.contact_name = agent.contact_name;
      state.contact_phone = agent.contact_phone;
      state.agent_level = agent.level == 1 ? "一级代理" : "二级代理";
    };

    onMounted(() => {
      loadAgentInfo();
    });

    return { state, canSubmit, submitForm };
  },
};
</script>
